.outcome-grid{
    margin-left: 0.938rem;
    margin-right: 0.938rem;

    .filter-content 
    .filter-header {
       background-color: #E6E7F2    ;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.9rem;}

}