@import "_pallet.scss";

.forget-password {
    width: 30%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 0.625rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: hidden;

    .image-container {
        background-color: #E6E7F2;
        padding: 0.5rem;
        display: flex;
        justify-content: center;

        img {
            width: 60%;
        }
    }

    form {
        padding: 1.5rem;

        input {
            width: 100%;
            font-size: 1.25rem;

        }

        button {
            width: 100%;
            background-color: #5464B0;
            border: 0;
            outline: 0;
            box-shadow: 0;
            color: #fff;
            font-size: 1.25rem;
            padding: 0.625rem 1rem;
            border-radius: 0.625rem;
            margin-top: 1.5rem;


        }
    }

}