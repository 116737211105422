@import "_pallet.scss";

.login-container {
    // width: 53.402%;
    width: 42.688rem;
    max-height: 100%;
    margin: 0 auto;
    max-width: 42.688rem;
    align-items: center;
    background-color: #fff;
    border-radius: 0.938rem;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-bottom: 5.188rem;

    &::-webkit-scrollbar {
        display: none;
    }


    .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #E6E7F2;
        padding: 1.625rem 0;

        img {
            width: 50%;
            margin: 0 auto;
            object-fit: contain;
        }
    }


    form {
        width: 29.688rem;
        margin: 0 auto;

        .password-container {

            a {

                font-size: 1.313rem;
                line-height: 1.563rem;
                font-family: $font-family-base, Arial, sans-serif;
                font-style: italic;
                color: #170C0C;
                margin-top: 1.8rem;
                display: inline-block;
            }


        }

        .button-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 3.375rem;

            span {
                display: inline-block;
                margin: 1rem 0;
                font-size: 1.313rem;
                line-height: 1.563rem;
                font-family: $font-family-base, Arial, sans-serif;
                color: #252C48;

            }

            button {
                width: 100%;
                display: inline-block;
                appearance: none;
                -webkit-appearance: none;
                border: 0;
                background-color: #fff;
                font-size: 1.125rem;
                line-height: 1.375rem;
                color: #fff;
                border: 0.125rem solid #5464B0;
                text-align: center;
                padding: 1rem 0;
                border-radius: 0.625rem;
                font-family: $font-family-base, Arial, sans-serif;
                font-weight: bold;

                &:first-of-type {
                    background-color: #5464B0;
                }

                &:last-of-type {
                    color: #5464B0;
                }
            }
        }
    }




}

.login-popup {
    .popup {
        width: 35% !important;

        .passcode-form-wrap {
            height: 100%;
            overflow-y: scroll;
        }

        .passcode-description {
            font-size: 1.3rem;
            display: inline-block;
            text-align: center;
            margin-bottom: 0;
            margin-top: 2rem;
            padding: 0 2.2rem;
        }

        .passcode-form {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 1.5rem 2.2rem;


            .filter-fields {
                flex-basis: 30% !important;
                margin-top: 0;

                .title {
                    text-align: center;
                }

                input {
                    text-align: center;
                }
            }

            .submit {
                margin-top: 2rem;
                flex-basis: 100%;
                padding: 1rem;
                background-color: #5464B0;
                border: 0;
                color: #fff;
                font-family: $font-family-base, Arial, Helvetica, sans-serif;
                border-radius: 0.625rem;
                font-weight: 700;
            }
        }
    }
}