@import "_pallet.scss";

.k-grid-toolbar, .k-grid-pager {
    border-color: transparent !important;
}

.k-column-title {
    font-weight: 700;
}

.k-grid .k-table-th,
.k-grid td,
.k-grid .k-table-td {
    border-inline-start-width: 0px !important;
}

.k-grid-pager {
    align-self: center;
}


.k-pager.k-pager-md.k-grid-pager {
    width: 100%;
}

.k-grid-toolbar {
    justify-content: end !important;
}

.k-pager-numbers {


    button.k-button-flat-primary {
        color: #000 !important;
        transition: .3s linear;

        &:hover {
            background-color: #5464B0 !important;
            border-radius: 0.625rem;
            color: #fff !important;
        }

        &.k-selected {
            background-color: #5464B0 !important;
            border-radius: 0.625rem;
            color: #fff !important;

            &::before {
                opacity: 0;
            }
        }
    }
}


.k-hierarchy-cell {
    a {
        padding: 0.5rem;
    }
}

.loading-data {
    position: absolute;
    background:rgba(255,255,255,0.4);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
color: #000;
    }