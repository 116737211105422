.addoutcome-popup {
    .modal.fade {
        display: block;
        opacity: 1;
        background-color: rgb(163, 163, 163, 0.55);
    }

    .modal-dialog {
        width: 49%;
        max-width: unset;
    }

    .modal-header {
        // width: 100%;
            background-color: #5464B0;
            display: flex;
        justify-content: space-between;
            padding: 1.5625rem 0 1.5625rem;
            margin-bottom: 0;

            display: flex;
        align-items: center;

            h5  {
                width: 100%;
                // text-align: center;
                font-weight: 700;
                font-size: 1.675rem;
                color: #fff ;
                padding-left: 2.188rem;
            }
    
            @media (max-width: 1440px) {
                padding: 1.2rem 0 0.5625rem;
            }
    
            @media (max-width: 1280px) {
                padding: 1.8125rem 0;
                font-size: 1.2rem;
            }
            .close{
                margin-right: 1.25rem;
            }
        }

    .information-governance-container {
        .content-container {
            margin: 0 auto;
            padding: 1.927rem;
            background-color: #fff;

            @media (max-width: 1440px) {
                padding: 0rem 0.927rem 0rem 0.927rem;
            }
        }

        .description {
            margin-bottom: 1.5rem;
            font-size: 1.06rem;

            @media (max-width: 1280px) {
                font-size: 0.8rem;
                margin-bottom: 1rem;
            }
        }

        .button {
            background-color: #E6E7F2;
            color: #434343;
            font-weight: 600;
            font-size: 1.0625rem;
            border: 0;
            outline: 0;
            min-width: 9.177rem;
            text-align: center;
            display: inline-block;
            padding: 0.6875rem 0;
            border-radius: 0.625rem;
            margin-bottom: 2.625rem;

            @media (max-width: 1440px) {
                margin-bottom: 1.625rem;
            }

            @media (max-width: 1280px) {
                font-size: 0.8rem;
                padding: 0.3 0;
                min-width: 6rem;

            }
        }

        .involve-case-title {
            font-size:1.188rem;
            color: #434343;
            font-weight: 600;
            margin-top: 1rem;
            margin-bottom: 1rem;


            @media (max-width: 1280px) {
                font-size: 0.8rem;
                margin-top: 1rem;
                margin-bottom: 1rem;

            }
        }

        .involve-case {
            width: 100%;
            appearance: none;
            border: 0.063rem solid #D8D8D8;
            border-radius: 0.313rem;
            font-size: 1.0625rem;
            display: block;
            padding: 0.9rem 1.0625rem 0.9rem;
            font-weight: 600;
            opacity: 50%;

            @media (max-width: 1280px) {
                font-size: 0.8rem;
            }
        }

        .involve-case-container {
            position: relative;
            &::after {
                content: "";
                width: 2.5rem;
                height: 2.5rem;
                display: inline-block;
                position: absolute;
                background: url("../../icons/chevron_down.svg") no-repeat center/cover;
                right: 0.625rem;
                top: 50%;
                transform: translateY(-50%);
                pointer-events: none;
            }
        }

        .min-width6 {
            margin-bottom: 0;
            min-width: 6rem;
        }

        .btn-info-close {
            background: #E6E7F2;
        }
    }

    .modal {
        opacity: 1;
        display: block !important;
    }
    .modal-footer{
        display: flex;
        justify-content: space-between;
        background-color: #5464B0;
        & .button {
            padding: 0.625rem 20px;
            border: none;
            border-radius: 0.25rem;
            cursor: pointer;
        }

        & .save-button {
            background-color: #fff;
            border: 0.063rem solid #fff; 
            color: #5464B0;
            padding: "0.625rem 1.188rem";
            font-weight: 700;
            border-radius : "0.625rem" ;
            font-size: 1rem;
            margin-right: 1.5rem;
        }

        & .cancel-button {
            background-color: #5464B0;
            border: 0.063rem solid #fff;
            color: #fff;
            border: "none"; 
            padding: "0.625rem 1.188rem";
            font-weight: 700;
            font-size: 1rem;
            border-radius: "0.625rem";
            margin-left: 1.5rem;
        }
    }
    .modal-body {
        // Body styles here
        // padding: 20px;

        .row {
            // Row styles here
            display: grid;
            grid-template-columns: auto auto;
            margin-bottom: 0.625rem;

            .column {
                // Column styles here
                flex: 1;
                margin-right: 0.313rem;
                margin-left: 0.313rem;

                label {
                    // Label styles here
                    display: block;
                    margin-bottom: 0.313rem;
                    font-size:1.188rem;
                    font-weight: 700;
                    color:#252C48;
                    border-radius: 0.313rem;
                }
                input{
                    width: 100%;
                    border: 0.063rem solid #D8D8D8;
                    opacity: 50%;
                    padding: 0.9rem 1.0625rem 0.9rem;
                    border-radius: 0.313rem;
                }
            }
        }
    }

}