
@import "_pallet.scss";


.btn-cta {
    font-size: 1rem;
    padding: 0.375rem 0.75rem;
    font-weight: 600;
    border-radius: 0.625rem;

    &.transparent-background {
        background-color: #fff;
        border: 1px solid #5464B0;
        color: #5464B0;
    }

    &.blue-background {
        background-color: #5464B0;
        border: 1px solid #5464B0;
        color: #fff;
    }

    &:disabled {
        background-color: #E0E0E0;
        border: 1px solid #E0E0E0;
        color: #fff;
    }
}