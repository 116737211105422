@import "_pallet.scss";


.aside-report-list {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
        border-bottom: 1px solid #D8D8D8;
        color: $xantura_primary;
    
        &.parent {
            padding: 0.8rem;
        }

        &:last-child {
            border-bottom: none;
        }

        ul.aside-report-list >li {
                border-bottom: none;
                padding-left: 3rem;
        }
    }

    .icon {
        width: 30px;
        height: 30px;
        overflow: hidden;
        margin: 0.5rem 0 0 0.5rem; 
    }

    .btn-link {
        text-decoration: none;
        padding: 0.8rem;
        color: $xantura_primary;
        font-weight: normal;
        font-size: 1.2rem;
        display: block;

        .bold {
            font-weight: bold;
        }

        &:disabled {
            color: #00000080;
            font-weight: normal;
        }

        &.active {
            background: #E6E7F2;
        }

        &.silveraccess {
            color: $xantura_primary;
            opacity: 0.5;
        }
    }

}