.top-header {
    background-color: #ffffff;

    .logo {
        height: 80%;
        margin-top: 0.5rem;
    }
}

.dashboard.reporting .filter-content {
    margin-top: 0;
}